<template>
    <div class="GlobalFormDemo">
        <GlobalForm :init-data="initData" :needBtnLoading="true" :form-item-list="formItemList" :inline="true"
            :labelWidth="90" round @handleConfirm="handleConfirm" confirmBtnName="查询">
            <el-form-item class="el_from" label="导入时间" slot="entryTime" style="margin:0" label-width="70px"
                v-if="record == '01'">
                <el-date-picker style="margin-left: 10px;" v-model="dateTime" :picker-options="pickerOptions"
                    size="small" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                    :clearable="false" @change="dateTimechange">
                </el-date-picker>
            </el-form-item>
            <el-form-item class="el_from" label="上账时间" slot="upentryTime" style="margin:0" label-width="70px"
                v-if="up == '01' && record == '02'">
                <el-date-picker style="margin-left: 10px;" v-model="updateTime" :picker-options="uppickerOptions"
                    size="small" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                    :clearable="false" @change="updateTimechange">
                </el-date-picker>
            </el-form-item>
            <!-- <el-form-item class="el_from" label="下账时间" slot="downentryTime" style="margin:0" label-width="70px"
                v-if="up == '02' && record == '02'">
                <el-date-picker style="margin-left: 10px;" v-model="downdateTime" :picker-options="downpickerOptions"
                    size="small" type="daterange" value-format="timestamp" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" :clearable="false">
                </el-date-picker>
            </el-form-item> -->
            <el-form-item class="el_from" label="下账时间" slot="downentryTime" style="margin:0" label-width="70px"
                v-if="up == '02' && record == '02'">
                <el-date-picker style="margin-left: 10px;" v-model="downentryTime" :picker-options="downpickerOptions"
                    size="small" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                    :clearable="false" @change="updateTimechange">
                </el-date-picker>
            </el-form-item>
            <el-form-item class="el_from" label="批次号" slot="batch" style="margin:0">
                <el-input v-model.trim="batch" placeholder="请输入批次号"></el-input>
            </el-form-item>
            <!-- <el-form-item class="el_from" label="订单号" slot="orderNo" style="margin:0">
                <el-input v-model.trim="orderNo" placeholder="请输入订单号查询"></el-input>
            </el-form-item> -->
            <el-form-item class="el_from" label="是否操作上账" slot="isUpAccount" style="margin:0" label-width="100px"
                v-if="record == '01' && up == '01'">
                <el-select v-model="isUpAccount" placeholder="请选择">
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="el_from" label="是否操作下账" slot="isDownAccount" style="margin:0" label-width="100px"
                v-if="record == '01' && up == '02'">
                <el-select v-model="isDownAccount" placeholder="请选择">
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="el_from" label="上账执行状态" slot="upAccountStatus" style="margin:0" label-width="100px"
                v-if="record == '01' && up == '01'">
                <el-select v-model="upAccountStatus" placeholder="请选择">
                    <el-option label="执行中" value="01"></el-option>
                    <el-option label="执行失败" value="10"></el-option>
                    <el-option label="待执行" value="00"></el-option>
                    <el-option label="执行成功" value="02"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="el_from" label="下账执行状态" slot="downAccountStatus" style="margin:0" label-width="100px"
                v-if="record == '01' && up == '02'">
                <el-select v-model="downAccountStatus" placeholder="请选择">
                    <el-option label="执行中" value="01"></el-option>
                    <el-option label="执行失败" value="10"></el-option>
                    <el-option label="待执行" value="00"></el-option>
                    <el-option label="执行成功" value="02"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="el_from" label="商户类型" slot="shopType" style="margin:0">
                <el-select v-model="shopType" placeholder="请选择">
                    <el-option label="回收商" value="merchant"></el-option>
                    <el-option label="门店商" value="company"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="el_from" label="上账操作人" slot="upPeople" v-if="record == '02' && up == '01'">
                <el-select v-model="upPeople" placeholder="请输入或选择操作人" filterable clearable>
                    <el-option v-for="item in upPeopleList" :key="item.id" :label="item.userName"
                        :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="el_from" label="下账操作人" slot="downPeople" v-if="record == '02' && up == '02'">
                <el-select v-model="downPeople" placeholder="请输入或选择操作人" filterable clearable>
                    <el-option v-for="item in downPeopleList" :key="item.id" :label="item.userName"
                        :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="el_from" label="回收商" slot="recyclers" v-if="record == '02'">
                <el-select v-model="recyclers" placeholder="请输入商家名称查询" filterable clearable>
                    <el-option v-for="item in recyclersList" :key="item.merchantId" :label="item.merchantName"
                        :value="item.merchantId"></el-option>
                </el-select>
            </el-form-item>
        </GlobalForm>
    </div>
</template>
<script>
import moment from "moment";
import _api from "@/utils/request";
export default {
    name: "Form",
    props: {
        up: {
            type: String
        },
        record: {
            type: String
        },
        batchNo: {
            type: String
        }
    },
    data() {
        return {
            SeachParams: {},//参数
            startTime: "",//开始时间
            endTime: "",//结束时间
            day60: 30 * 24 * 3600 * 1000,//时间戳
            day31: 30 * 24 * 3600 * 1000,
            initData: null,//初始数据
            formItemList: [
                { slotName: "entryTime" },
                { slotName: "upentryTime" },
                { slotName: "downentryTime" },
                { slotName: "batch" },
                // { slotName: "orderNo" },
                { slotName: "isUpAccount" },
                { slotName: "isDownAccount" },
                { slotName: "upAccountStatus" },
                { slotName: "downAccountStatus" },
                { slotName: "upPeople" },
                { slotName: "downPeople" },
                { slotName: "shopType" },
                { slotName: "recyclers" },
            ],//表单prop
            dateTime: [          moment()
            .day(moment().day() - 30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),],//导入时间
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    if (minDate && this.pickerMinDate) {
                        this.pickerMinDate = null;
                    } else if (minDate) {
                        this.pickerMinDate = minDate.getTime();
                    }
                },
                disabledDate: (time) => {
                    if (this.pickerMinDate) {
                        return (
                            time.getTime() > this.pickerMinDate + this.day31 ||
                            time.getTime() < this.pickerMinDate - this.day31
                        );
                    }
                    return false;
                },
            },//选定一个月时间函数
            uppickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    if (minDate && this.pickerMinDate) {
                        this.pickerMinDate = null;
                    } else if (minDate) {
                        this.pickerMinDate = minDate.getTime();
                    }
                },
                disabledDate: (time) => {
                    if (this.pickerMinDate) {
                        return (
                            time.getTime() > this.pickerMinDate + this.day31 ||
                            time.getTime() < this.pickerMinDate - this.day31
                        );
                    }
                    return false;
                },
            },//选定一个月上账时间函数
            downpickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    if (minDate && this.pickerMinDate) {
                        this.pickerMinDate = null;
                    } else if (minDate) {
                        this.pickerMinDate = minDate.getTime();
                    }
                },
                disabledDate: (time) => {
                    if (this.pickerMinDate) {
                        return (
                            time.getTime() > this.pickerMinDate + this.day31 ||
                            time.getTime() < this.pickerMinDate - this.day31
                        );
                    }
                    return false;
                },
            },//选定一个月下账时间函数
            batch: "",//批次
            // orderNo: "",//订单号
            isUpAccount: "",//是否上账
            upAccountStatus: "",//上账执行状态
            shopType: "",//商户类型
            upPeople: "",//上账人
            upPeopleList: [],//上账人数组
            recyclers: "",//回收商商家名称
            recyclersList: [],//回收商商家集合
            updateTime: [ moment()
            .day(moment().day() - 30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),],//上账时间,
            isDownAccount: "",//是否下账
            downAccountStatus: "",//下账执行状态
            downdateTime: [moment()
                .day(moment().day())
                .format("YYYY-MM-DD 00:00:00"),
            moment().add(30, 'days').format("YYYY-MM-DD 23:59:59")],//下账时间
            downPeople: "",//下账人
            downPeopleList: [],//下账人数组
            downentryTime:[ moment()
            .day(moment().day() - 30)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),]
        };
    },
    created() {
        this.listSimpleMerchantView()
        this.StaffManageList()
        this.handleConfirm();
    },
    watch: {
        record(val) {
            this.batch = ""
            this.handleConfirm()
        },
        up() {
            this.batch = ""
            this.handleConfirm()
        },
        batchNo(RecallOrder) {
            console.log(RecallOrder,"RecallOrder")
            this.batch = this.batchNo || ""
            this.handleConfirm()
        }
    },
    methods: {
        handleConfirm(data, cd) {//查询
            console.log(this.dateTime[0], 99999)

            let SeachParams = {};
            // if (this.orderNo) {
            //     SeachParams = {
            //         orderNo: this.orderNo
            //     };
            // }
            if (this.up == "01" && this.record == "01") {

                let startTime = moment(this.dateTime[0]).format("x");
                let endTime = moment(this.dateTime[1]).format("x");
                SeachParams = {
                    accountType: this.shopType,
                    batchNo: this.batch,
                    endTime: endTime,
                    opt: "00",
                    pageNum: 1,
                    pageSize: 10,
                    startTime: startTime,
                    state: this.upAccountStatus
                };
                if(!this.isUpAccount){
                    console.log(1)
                    SeachParams.isOpt=""
                }
                if(this.isUpAccount){
                    if(this.isUpAccount=="1"){
                        console.log(2)
                        SeachParams.isOpt=true
                    }
                    if(this.isUpAccount=="0"){
                        console.log(3)
                        SeachParams.isOpt=false
                    }
                }
                console.log(SeachParams,"98")
                this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
            }
            if (this.up == "01" && this.record == "02") {
                let startTime = moment(this.updateTime[0]).format("x");
                let endTime = moment(this.updateTime[1]).format("x");
                SeachParams = {
                    accountType: this.shopType,
                    batchNo: this.batch,
                    endTime: endTime,
                    opt: "00",
                    pageNum: 1,
                    pageSize: 10,
                    startTime: startTime,
                    enterpriseId: this.recyclers,
                    adminId: this.upPeople
                };
                this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
            }
            if (this.up == "02" && this.record == "01") {
                let startTime = moment(this.updateTime[0]).format("x");
                let endTime = moment(this.updateTime[1]).format("x");
                SeachParams = {
                    accountType: this.shopType,
                    batchNo: this.batch,
                    endTime: endTime,
                    opt: "01",
                    pageNum: 1,
                    pageSize: 10,
                    startTime: startTime,
                    state: this.downAccountStatus
                };
                if(!this.isDownAccount){
                    SeachParams.isOpt=""
                }
                if(this.isDownAccount){
                    if(this.isDownAccount=="1"){
                        SeachParams.isOpt=true
                    }
                    if(this.isDownAccount=="0"){
                        SeachParams.isOpt=false
                    }
                }
                this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
            }
            if (this.up == "02" && this.record == "02") {
                let startTime = moment(this.downentryTime[0]).format("x");
                let endTime = moment(this.downentryTime[1]).format("x");
                SeachParams = {
                    accountType: this.shopType,
                    batchNo: this.batch,
                    endTime: endTime,
                    opt: "01",
                    pageNum: 1,
                    pageSize: 10,
                    startTime: startTime,
                    enterpriseId: this.recyclers,
                    adminId: this.upPeople
                };
                this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
            }
            SeachParams.disuseLoding = () => {
                if (typeof cd === 'function') {
                    cd();
                }
            },
                this.SeachParams = SeachParams
            console.log(SeachParams);

        },
        dateTimechange(val) {
            console.log(val, 999)
            this.dateTime = [val[0], val[1]]
        },
        updateTimechange(val) {
            this.updateTime = [val[0], val[1]]
        },
        listSimpleMerchantView() {
            _api
                .listSimpleMerchantView({
                    pageNum: 0,
                    pageSize: 999999,
                })
                .then((res) => {
                    this.recyclersList = res.data;
                });
        },
        StaffManageList() {
            _api
                .StaffManageList({
                    pageNum: 0,
                    pageSize: 999999,
                })
                .then((res) => {
                    this.upPeopleList = res.data.records;
                    this.downPeopleList = res.data.records
                });
        }
    },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {}
</style>